<template>
  <div class="foot">
      <p>
          CopyRight © 91再生 2005 - 2022&nbsp; 增值电信业务经营许可证：<a href="https://tsm.miit.gov.cn/dxxzsp/xkz/xkzgl/resource/qiyesearch.jsp?num=浙B2-20190161&type=xuke" target="_blank">浙B2-20190161</a>&nbsp;&nbsp; 浙ICP备 <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">浙B2-20080212-5</a>&nbsp;&nbsp; 浙公网安备 <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010402001336" target="_blank">33010402001336号</a>
      </p>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.foot {
    margin-top: 52px;
    color: #999999;
    font-size: 13px;
    min-width: 990px;
    @media screen and (max-width: 1440px){
    padding: 0 15px;
    }
    a {
        color: #999999;
        outline: none;
        cursor: pointer;
        transition: color .3s;
        text-decoration: none;
        &:hover {
            color: @primary;
        }
    }
    p {
        text-align: center;
    }
}
</style>