<template>
  <div class="units-box">
    <div class="units-content">
      <MdTitle label="合作单位" iconName="dribbble-square"></MdTitle>
      <div class="units-imgs clear_fix">
        <div class="units-imgs-item">
          <a href="https://bank.pingan.com/" target="_blank">
            <img src="../../assets/images/unit1.png"/>
          </a>
        </div>
        <div class="units-imgs-item">
          <a href="http://www.czbank.com/cn/index.shtml" target="_blank">
            <img src="../../assets/images/unit2.png"/>
          </a>
        </div>
        <div class="units-imgs-item">
          <a href="http://www.chinacoop.gov.cn/" target="_blank">
            <img src="../../assets/images/unit3.png"/>
          </a>
        </div>
        <div class="units-imgs-item">
          <a href="http://www.crra.org.cn/" target="_blank">
            <img src="../../assets/images/unit4.png"/>
          </a>
        </div>
        <div class="units-imgs-item">
          <a href="http://www.cppia.com.cn/" target="_blank">
            <img src="../../assets/images/u509.png"/>
          </a>
        </div>
      </div>
      <div class="units-imgs clear_fix">
        <div class="units-imgs-item">
          <a href="https://www.chinania.org.cn/" target="_blank">
            <img src="../../assets/images/u510.png"/>
          </a>
        </div>
        <div class="units-imgs-item">
          <a href="http://www.camu.org.cn/" target="_blank">
            <img src="../../assets/images/u511.png"/>
          </a>
        </div>
        <div class="units-imgs-item">
          <a href="http://www.crra.com.cn/" target="_blank">
            <img src="../../assets/images/u514.png"/>
          </a>
        </div>
        <div class="units-imgs-item">
          <a href="http://www.crra.com.cn/" target="_blank">
            <img src="../../assets/images/u512.png"/>
          </a>
        </div>
        <div class="units-imgs-item">
          <a href="https://www.zjepa.com.cn/" target="_blank">
            <img src="../../assets/images/u513.png"/>
          </a>
        </div>
      </div>
      <!-- <ul class="units-labels clear_fix">
        <li>中塑在线</li> 
        <li>招商宝</li> 
        <li>家居建材网</li> 
        <li>新能源产业</li> 
        <li>商机网</li> 
        <li>云同网</li> 
      </ul> -->
    </div>
  </div>
</template>
<script>
import MdTitle from './MdTitle.vue'
export default {
  components:{
    MdTitle
  }
}
</script>
<style lang="less" scoped>
.units-box{
  background-color: #fff;
  min-width: 990px;
}
  .units-content{
    max-width: 1440px;
    min-width: 990px;
    margin: 0 auto;
    background-color: #fff;
    padding: 35px 0 70px;
    .units-imgs{
      margin-top: 40px;
      .units-imgs-item{
        float: left;
        width: 20%;
        img{
          // width: 140px;
          height: 54px;
          position: relative;
          // left: -15px;
        }
      }
    }
    
    .units-labels{
      li{
        float: left;
        width: 20%;
        // margin-right:80px;
        margin-top: 15px;
        font-size: 16px;
        &:nth-child(3n+0){
          margin-right: 0;
        }
      }
      
    }
  }
</style>