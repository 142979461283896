import request from '@/utils/request'


// 购物车总数
export function cartAmount(params) {
    return request({
        url: '/shopping/car/amount',
        method: 'get',
        params
    })
}

// 新增购物车
export function cartAdd(data) {
    return request({
        url: '/shopping/car/add',
        method: 'post',
        data
    })
}

// 购物车-结算-预览
export function cartPreview(data) {
    return request({
        url: '/orders/shopping/cart/preview',
        method: 'post',
        data
    })
}

// 购物车-列表
export function cartList(params) {
    return request({
        url: '/shopping/car/list',
        method: 'get',
        params
    })
}

// 购物车-删除
export function cartDel(data) {
    return request({
        url: '/shopping/car/del',
        method: 'post',
        data
    })
}

// 购物车进入-生成订单
export function cartCreate(data) {
    return request({
        url: '/orders/shopping/cart/create',
        method: 'post',
        data
    })
}

// 清除失效购物车
export function cartIznvalidDel(data) {
    return request({
        url: '/shopping/car/invalid/remove',
        method: 'post',
        data
    })
}