/* eslint-disable */
;
(function() {
    var h;

    function l(a) {
        var c = 0;
        return function() {
            return c < a.length ? {
                done: !1,
                value: a[c++]
            } : {
                done: !0
            }
        }
    }
    var m = "function" == typeof Object.defineProperties ? Object.defineProperty : function(a, c, b) {
        if (a == Array.prototype || a == Object.prototype) return a;
        a[c] = b.value;
        return a
    };

    function n(a) {
        a = ["object" == typeof globalThis && globalThis, a, "object" == typeof window && window, "object" == typeof self && self, "object" == typeof global && global];
        for (var c = 0; c < a.length; ++c) {
            var b = a[c];
            if (b && b.Math == Math) return b
        }
        throw Error("Cannot find global object");
    }
    var p = n(this);

    function r(a, c) {
        if (c) {
            for (var b = p, d = a.split("."), e = 0; e < d.length - 1; e++) {
                var f = d[e];
                f in b || (b[f] = {});
                b = b[f]
            }
            d = d[d.length - 1];
            e = b[d];
            f = c(e);
            f != e && null != f && m(b, d, {
                configurable: !0,
                writable: !0,
                value: f
            })
        }
    }
    r("Symbol", function(a) {
        function c(e) {
            if (this instanceof c) throw new TypeError("Symbol is not a constructor");
            return new b("jscomp_symbol_" + (e || "") + "_" + d++, e)
        }

        function b(e, f) {
            this.o = e;
            m(this, "description", {
                configurable: !0,
                writable: !0,
                value: f
            })
        }
        if (a) return a;
        b.prototype.toString = function() {
            return this.o
        };
        var d = 0;
        return c
    });
    r("Symbol.iterator", function(a) {
        if (a) return a;
        a = Symbol("Symbol.iterator");
        for (var c = "Array Int8Array Uint8Array Uint8ClampedArray Int16Array Uint16Array Int32Array Uint32Array Float32Array Float64Array".split(" "), b = 0; b < c.length; b++) {
            var d = p[c[b]];
            "function" === typeof d && "function" != typeof d.prototype[a] && m(d.prototype, a, {
                configurable: !0,
                writable: !0,
                value: function() {
                    return u(l(this))
                }
            })
        }
        return a
    });

    function u(a) {
        a = {
            next: a
        };
        a[Symbol.iterator] = function() {
            return this
        };
        return a
    }

    function v(a) {
        var c = "undefined" != typeof Symbol && Symbol.iterator && a[Symbol.iterator];
        return c ? c.call(a) : {
            next: l(a)
        }
    }
    var w;
    if ("function" == typeof Object.setPrototypeOf) w = Object.setPrototypeOf;
    else {
        var y;
        a: {
            var z = {
                    u: !0
                },
                A = {};
            try {
                A.__proto__ = z;
                y = A.u;
                break a
            } catch (a) {}
            y = !1
        }
        w = y ? function(a, c) {
            a.__proto__ = c;
            if (a.__proto__ !== c) throw new TypeError(a + " is not extensible");
            return a
        } : null
    }
    var B = w;

    function C() {
        this.h = !1;
        this.f = null;
        this.m = void 0;
        this.b = 1;
        this.l = this.v = 0;
        this.g = null
    }

    function D(a) {
        if (a.h) throw new TypeError("Generator is already running");
        a.h = !0
    }
    C.prototype.i = function(a) {
        this.m = a
    };
    C.prototype.j = function(a) {
        this.g = {
            w: a,
            A: !0
        };
        this.b = this.v || this.l
    };
    C.prototype["return"] = function(a) {
        this.g = {
            "return": a
        };
        this.b = this.l
    };

    function E(a, c) {
        a.b = 3;
        return {
            value: c
        }
    }

    function F(a) {
        this.a = new C;
        this.B = a
    }
    F.prototype.i = function(a) {
        D(this.a);
        if (this.a.f) return G(this, this.a.f.next, a, this.a.i);
        this.a.i(a);
        return H(this)
    };

    function I(a, c) {
        D(a.a);
        var b = a.a.f;
        if (b) return G(a, "return" in b ? b["return"] : function(d) {
            return {
                value: d,
                done: !0
            }
        }, c, a.a["return"]);
        a.a["return"](c);
        return H(a)
    }
    F.prototype.j = function(a) {
        D(this.a);
        if (this.a.f) return G(this, this.a.f["throw"], a, this.a.i);
        this.a.j(a);
        return H(this)
    };

    function G(a, c, b, d) {
        try {
            var e = c.call(a.a.f, b);
            if (!(e instanceof Object)) throw new TypeError("Iterator result " + e + " is not an object");
            if (!e.done) return a.a.h = !1, e;
            var f = e.value
        } catch (g) {
            return a.a.f = null, a.a.j(g), H(a)
        }
        a.a.f = null;
        d.call(a.a, f);
        return H(a)
    }

    function H(a) {
        for (; a.a.b;) try {
            var c = a.B(a.a);
            if (c) return a.a.h = !1, {
                value: c.value,
                done: !1
            }
        } catch (b) {
            a.a.m = void 0, a.a.j(b)
        }
        a.a.h = !1;
        if (a.a.g) {
            c = a.a.g;
            a.a.g = null;
            if (c.A) throw c.w;
            return {
                value: c["return"],
                done: !0
            }
        }
        return {
            value: void 0,
            done: !0
        }
    }

    function J(a) {
        this.next = function(c) {
            return a.i(c)
        };
        this["throw"] = function(c) {
            return a.j(c)
        };
        this["return"] = function(c) {
            return I(a, c)
        };
        this[Symbol.iterator] = function() {
            return this
        }
    }

    function K(a, c) {
        var b = new J(new F(c));
        B && B(b, a.prototype);
        return b
    }
    if ("undefined" !== typeof Blob && ("undefined" === typeof FormData || !FormData.prototype.keys)) {
        var L = function(a, c) {
                for (var b = 0; b < a.length; b++) c(a[b])
            },
            M = function(a, c, b) {
                return c instanceof Blob ? [String(a), c, void 0 !== b ? b + "" : "string" === typeof c.name ? c.name : "blob"] : [String(a), String(c)]
            },
            N = function(a, c) {
                if (a.length < c) throw new TypeError(c + " argument required, but only " + a.length + " present.");
            },
            O = function(a) {
                var c = v(a);
                a = c.next().value;
                var b = c.next().value;
                c = c.next().value;
                b instanceof Blob && (b = new File([b],
                    c, {
                        type: b.type,
                        lastModified: b.lastModified
                    }));
                return [a, b]
            },
            P = "object" === typeof globalThis ? globalThis : "object" === typeof window ? window : "object" === typeof self ? self : this,
            Q = P.FormData,
            R = P.XMLHttpRequest && P.XMLHttpRequest.prototype.send,
            S = P.Request && P.fetch,
            T = P.navigator && P.navigator.sendBeacon,
            U = P.Element && P.Element.prototype,
            V = P.Symbol && Symbol.toStringTag;
        V && (Blob.prototype[V] || (Blob.prototype[V] = "Blob"), "File" in P && !File.prototype[V] && (File.prototype[V] = "File"));
        try {
            new File([], "")
        } catch (a) {
            P.File = function(c,
                b, d) {
                c = new Blob(c, d);
                d = d && void 0 !== d.lastModified ? new Date(d.lastModified) : new Date;
                Object.defineProperties(c, {
                    name: {
                        value: b
                    },
                    lastModifiedDate: {
                        value: d
                    },
                    lastModified: {
                        value: +d
                    },
                    toString: {
                        value: function() {
                            return "[object File]"
                        }
                    }
                });
                V && Object.defineProperty(c, V, {
                    value: "File"
                });
                return c
            }
        }
        var W = function(a) {
            this.c = [];
            var c = this;
            a && L(a.elements, function(b) {
                if (b.name && !b.disabled && "submit" !== b.type && "button" !== b.type && !b.matches("form fieldset[disabled] *"))
                    if ("file" === b.type) {
                        var d = b.files && b.files.length ?
                            b.files : [new File([], "", {
                                type: "application/octet-stream"
                            })];
                        L(d, function(e) {
                            c.append(b.name, e)
                        })
                    } else "select-multiple" === b.type || "select-one" === b.type ? L(b.options, function(e) {
                        !e.disabled && e.selected && c.append(b.name, e.value)
                    }) : "checkbox" === b.type || "radio" === b.type ? b.checked && c.append(b.name, b.value) : (d = "textarea" === b.type ? b.value.replace(/\r\n/g, "\n").replace(/\n/g, "\r\n") : b.value, c.append(b.name, d))
            })
        };
        h = W.prototype;
        h.append = function(a, c, b) {
            N(arguments, 2);
            this.c.push(M(a, c, b))
        };
        h["delete"] = function(a) {
            N(arguments,
                1);
            var c = [];
            a = String(a);
            L(this.c, function(b) {
                b[0] !== a && c.push(b)
            });
            this.c = c
        };
        h.entries = function c() {
            var b, d = this;
            return K(c, function(e) {
                1 == e.b && (b = 0);
                if (3 != e.b) return b < d.c.length ? e = E(e, O(d.c[b])) : (e.b = 0, e = void 0), e;
                b++;
                e.b = 2
            })
        };
        h.forEach = function(c, b) {
            N(arguments, 1);
            for (var d = v(this), e = d.next(); !e.done; e = d.next()) {
                var f = v(e.value);
                e = f.next().value;
                f = f.next().value;
                c.call(b, f, e, this)
            }
        };
        h.get = function(c) {
            N(arguments, 1);
            var b = this.c;
            c = String(c);
            for (var d = 0; d < b.length; d++)
                if (b[d][0] === c) return O(b[d])[1];
            return null
        };
        h.getAll = function(c) {
            N(arguments, 1);
            var b = [];
            c = String(c);
            L(this.c, function(d) {
                d[0] === c && b.push(O(d)[1])
            });
            return b
        };
        h.has = function(c) {
            N(arguments, 1);
            c = String(c);
            for (var b = 0; b < this.c.length; b++)
                if (this.c[b][0] === c) return !0;
            return !1
        };
        h.keys = function b() {
            var d = this,
                e, f, g, k, q;
            return K(b, function(t) {
                1 == t.b && (e = v(d), f = e.next());
                if (3 != t.b) {
                    if (f.done) {
                        t.b = 0;
                        return
                    }
                    g = f.value;
                    k = v(g);
                    q = k.next().value;
                    return E(t, q)
                }
                f = e.next();
                t.b = 2
            })
        };
        h.set = function(b, d, e) {
            N(arguments, 2);
            b = String(b);
            var f = [],
                g = M(b,
                    d, e),
                k = !0;
            L(this.c, function(q) {
                q[0] === b ? k && (k = !f.push(g)) : f.push(q)
            });
            k && f.push(g);
            this.c = f
        };
        h.values = function d() {
            var e = this,
                f, g, k, q, t;
            return K(d, function(x) {
                1 == x.b && (f = v(e), g = f.next());
                if (3 != x.b) {
                    if (g.done) {
                        x.b = 0;
                        return
                    }
                    k = g.value;
                    q = v(k);
                    q.next();
                    t = q.next().value;
                    return E(x, t)
                }
                g = f.next();
                x.b = 2
            })
        };
        W.prototype._asNative = function() {
            for (var d = new Q, e = v(this), f = e.next(); !f.done; f = e.next()) {
                var g = v(f.value);
                f = g.next().value;
                g = g.next().value;
                d.append(f, g)
            }
            return d
        };
        W.prototype._blob = function() {
            for (var d =
                    "----formdata-polyfill-" + Math.random(), e = [], f = v(this), g = f.next(); !g.done; g = f.next()) {
                var k = v(g.value);
                g = k.next().value;
                k = k.next().value;
                e.push("--" + d + "\r\n");
                k instanceof Blob ? e.push('Content-Disposition: form-data; name="' + g + '"; filename="' + k.name + '"\r\nContent-Type: ' + ((k.type || "application/octet-stream") + "\r\n\r\n"), k, "\r\n") : e.push('Content-Disposition: form-data; name="' + g + '"\r\n\r\n' + k + "\r\n")
            }
            e.push("--" + d + "--");
            return new Blob(e, {
                type: "multipart/form-data; boundary=" + d
            })
        };
        W.prototype[Symbol.iterator] =
            function() {
                return this.entries()
            };
        W.prototype.toString = function() {
            return "[object FormData]"
        };
        U && !U.matches && (U.matches = U.matchesSelector || U.mozMatchesSelector || U.msMatchesSelector || U.oMatchesSelector || U.webkitMatchesSelector || function(d) {
            d = (this.document || this.ownerDocument).querySelectorAll(d);
            for (var e = d.length; 0 <= --e && d.item(e) !== this;);
            return -1 < e
        });
        V && (W.prototype[V] = "FormData");
        if (R) {
            var X = P.XMLHttpRequest.prototype.setRequestHeader;
            P.XMLHttpRequest.prototype.setRequestHeader = function(d, e) {
                X.call(this,
                    d, e);
                "content-type" === d.toLowerCase() && (this.s = !0)
            };
            P.XMLHttpRequest.prototype.send = function(d) {
                d instanceof W ? (d = d._blob(), this.s || this.setRequestHeader("Content-Type", d.type), R.call(this, d)) : R.call(this, d)
            }
        }
        S && (P.fetch = function(d, e) {
            e && e.body && e.body instanceof W && (e.body = e.body._blob());
            return S.call(this, d, e)
        });
        T && (P.navigator.sendBeacon = function(d, e) {
            e instanceof W && (e = e._asNative());
            return T.call(this, d, e)
        });
        P.FormData = W
    };
})();