import request from '@/utils/request'

// 返回当前用户关联主体信息及角色
export function menu() {
    return request({
        url: '/authority/menu',
        method: 'get',
    })
}

// 返回用户主体信息
export function subject(entityCharacter, entityId) {
    return request({
        url: '/trade/pur/subject',
        method: 'get',
        params: {entityCharacter, entityId}
    })
}

// 获取搜索页详情页面
export function purchase(id, purchaserId) {
    const url = purchaserId ? '?purchaserId=' + purchaserId : ''
    return request({
        url: '/merchandise/purchase/'+id + url,
        method: 'get'
    })
}

// 获取税收区域列表接口
export function regions() {
    return request({
        url: '/tax/regions',
        method: 'get',
    })
}

// 商品公开搜索列表
export function list(params) {
    return request({
        url: '/merchandise/search/list',
        method: 'get',
        params
    })
}

// 查找银行关联号
export function branch({cityCode, bankCode, keywords}) {
    return request({
        url: '/bank/zs/find/branch',
        method: 'get',
        params: {cityCode, bankCode, keywords}
    })
}


// 商品上新
export function newest() {
    return request({
        url: '/data/stats/merch/newest',
        method: 'get'
    })
}

// 近期订单
export function recentOrder() {
    return request({
        url: '/data/stats/orders/recent/created',
        method: 'get'
    })
}

// 添加或替换签署人手签图片
export function signerSig(data) {
    return request({
        url: '/trade/signer/sig',
        method: 'post',
        data
    })
}

// 添加或替换签署人手签图片
export function signerConfig(data) {
    return request({
        url: '/trade/signer/config',
        method: 'post',
        data
    })
}
