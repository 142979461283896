<template>
  <div class="footer">
    <div class="footer-main">
      <div class="footer-center clear_fix">
        <div class="fl footer-wrap">
          <dl class="fl wrap-list">
            <dt><a>新手指南</a></dt>
            <dd><router-link to="/register">注册新用户</router-link></dd>
            <dd><router-link to="/forgotPwd">找回密码</router-link></dd>
            <!-- <dd><router-link to="/helpcenter">帮助中心</router-link></dd> -->
            <dd><router-link to="/notice">公告栏</router-link></dd>
          </dl>
          <dl class="fl wrap-list">
            <dt><a>关于91</a></dt>
            <dd><router-link to="/about">关于我们</router-link> </dd>
            <dd><router-link to="/about/contact">联系我们</router-link></dd>
          </dl>
          <dl class="fl wrap-list">
            <dt><a>资讯</a></dt>
            <dd><a href="http://jiage.zz91.com/" target="_blank">看行情</a></dd>
            <dd><a href="http://news.zz91.com/" target="_blank">资讯中心</a></dd>
          </dl>
          <dl class="fl wrap-list">
            <dt><a>快捷入口</a></dt>
            <dd><a href="http://www.zz91.com/" target="_blank">91再生</a></dd>
            <dd><a href="http://www.zz91.com/zsh/index.html" target="_blank">再生汇</a></dd>
            <dd><a href="http://www.asto.com.cn/" target="_blank">ASTO官网</a></dd>
          </dl>
          <dl class="fl wrap-list">
            <dt><a>扫码咨询</a></dt>
            <dd>
              <img src="../../assets/images/wx-code.png" alt="" width="90">
            </dd>
            <dd></dd>
          </dl>
        </div>
        <div class="foot-logo fr">
          <img src="../../assets/images/logo2.png" alt="">
          <div>
            <p>技术支持：91再生</p> 
          <p>Powered by zz91. com</p>
          </div> 
        </div>
      </div>
      <div class="foot-ipc">
        CopyRight © 91再生 2005 - 2022 增值电信业务经营许可证：<a href="https://tsm.miit.gov.cn/dxxzsp/xkz/xkzgl/resource/qiyesearch.jsp?num=浙B2-20190161&type=xuke" target="_blank">浙B2-20190161</a> 浙ICP备 <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">浙B2-20080212-5</a> <i class="icon-jinhun"></i>浙公网安备 <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010402001336" target="_blank">33010402001336号</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.footer {
  background-color: #001529;
  padding: 50px 0;
  min-width:990px;
  .footer-main {
    max-width: 1440px;
    // min-width: 990px;
    margin: 0 auto;
    @media screen and(max-width:1440px) {
      padding-left:15px;
      padding-right: 15px;
    }
    .footer-center{
      padding: 50px 0;
    }
    .footer-wrap {
      .wrap-list {
        margin-right: 100px;
        dt {
          margin-bottom: 12px;
          a {
            color: #eff3f5;
            &:hover{
              text-decoration: underline;
            }
          }
        }
        dd a {
          color: #9fa2a5;
          line-height: 25px;
          &:hover{
            text-decoration: underline;
          }
        }
      }
    }
    .foot-ipc{
      .icon-jinhun{
            background: url(../../assets/images/iconimg.png) repeat scroll -110px -126px;
            display: inline-block;
            height: 20px;
            margin:0 3px;
            vertical-align: middle;
            width: 18px;
      }
      a {
        color: #7a7d7f;
        outline: none;
        cursor: pointer;
        transition: color .3s;
        text-decoration: none;
        &:hover {
          color: @primary;
        }
      }
      text-align: center;
      color: #7a7d7f;
    }
    .foot-logo {
      div{
        padding-top: 10px;
        p{
        color: #9fa2a5;
        line-height: 20px;
      } 
      }
     
    }
  }
}
</style>