<template>
  <div class="band-swiper">
    <swiper ref="mySwiper" :options="swiperOptions">
      <!-- <swiper-slide>
         <div class="first-slide" :style="{backgroundImage: `url('${img.three}')`}">
          <img src="../../assets/images/swiper2.jpg" alt="绿色买卖">
        </div>
      </swiper-slide> -->
      <swiper-slide >
        <div class="first-slide" :style="{backgroundImage: `url('${img.one}')`}">
          <!-- <img src="../../assets/images/swiper1.jpg" alt="绿色买卖"> -->
        </div>
      </swiper-slide>
      <!-- <swiper-slide>
         <div class="first-slide" :style="{backgroundImage: `url('${img.two}')`}">
          <img src="../../assets/images/swiper2.jpg" alt="绿色买卖">
        </div>
      </swiper-slide> -->
      
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
    
  </div>
</template>
<script>
export default {
  data() {
    return {
      swiperOptions: {
        autoHeight: true,
        autoplay: {
          delay: 5000,
        },
        pagination: {
          el: ".swiper-pagination",
        },
        // Some Swiper option/callback...
      },
      img: {
        one: require('../../assets/images/swiper1.jpg'),
        two: require('../../assets/images/swiper2.jpg'),
        three:require('../../assets/images/swiper3.png')
      }
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  mounted() {
    console.log(this.swiper);
    // this.swiper.slideTo(0, 1000, false);
  },
};
</script>
<style lang="less">
.band-swiper {
  width: 100%;
  // min-width: 1440px;
  height: 505px;
  position: relative;
}
.swiper-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.swiper-slide{
  .s-silde-img{
    width: 100%;
    height: 100%;
    background-position: center center;
  }
}
.swiper-pagination {
  bottom: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .swiper-pagination-bullet{
    background-color: #fff;
    margin:0 10px;
    &.swiper-pagination-bullet-active{
      background-color: @primary;;

    }
  }
}
.first-slide{
  // width: 100%;
  max-width: 1905px;
  margin: 0 auto;
  height: 100%;
  text-align: center;
  background-position: center;
  background-size: cover;
  // .slide-left{
  //   padding: 20px 0 0 20px;
  // }
  // .page-power{
  //   font-size: 30px;
  //   line-height: 36px;
  //   font-weight: bold;
  //   span{
  //     font-weight: normal;
  //     display: block;
  //     margin-top: 15px;
  //     font-size:24px;
  //   }
  // }
}
</style>