<template>
  <div class="intro">
    <div class="intro-context">
      <div class="intro-left">
        <div class="intro-title">91再生交易中心</div>
        <div class="intro-center">再生资源回收行业合规交易解决方案，依托于91再生的行业经验及技术手段，通过交易五流进行交易真实性验证。从行业合规性出发，遵循财税40号文，为再生资源回收企业、供货商提供专业便捷的交易合规指导，降低经营风险。</div>
        <a-button type="primary" class="intro-button" @click="goweb">了解产品</a-button>
      </div>
      <img src="../../assets/images/intro.png" alt="">
    </div>
  </div>
</template>
<script>
  export default{
    name:'Intro',
    data(){
      return {}
    },
    methods:{
      goweb(){
        this.$router.push('/products')
      }
    }
  }
</script>
<style lang="less" scoped>
  .intro {
    background-color: #fff;
    min-width: 990px;
    .intro-context{
      max-width: 1440px;
      min-width: 990px;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      padding: 80px 0;
    }
    .intro-title{
      font-size: 32px;
      font-weight: bold;
      color: #666666;
    }
    .intro-center{
      width: 560px;
      font-size: 16px;
      line-height: 27px;
      margin-top: 20px;
      color: #7F7F7F;
    }
    .intro-button{
      margin-top: 50px;
    }
  }
</style>