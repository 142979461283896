<template>
  <div>
      <NoLoginNav />

      <div class="container">
        <div class="user">
          <div class="user-active">
              <!-- 登录切换 -->
              <div class="switch-login">
                  <div :class="index === active && 'active'" v-for="(item,index) in switchList" :key="index" @click="active = index">{{item}}</div>
              </div>

              <div class="login-content">
                <!-- 切换内容 -->
                <div class="login-container" :style="{transform: `translateX(${!active ? 0 : -360}px)`}">
                    <!-- 验证码登录 -->
                    <div class="login">
                        <uInput
                        name="phone"
                        class="login-input"
                        ref="code"
                        :phoneSign="true"
                        v-model="phoneForm.phone"
                        max="11"
                        placeholder="手机号"
                        :rule="{rule: /^[1][3,4,5,6,7,8,9][0-9]{9}$/}"
                        @clear="phoneForm.phone = $event">
                            <template #icon>
                                <a-icon type="user" />
                            </template>
                        </uInput>

                        <div class="input-code">
                          <uInput
                          isPwd
                          class="code"
                          :phoneSign="true"
                          max="6"
                          v-model="phoneForm.code"
                          placeholder="输入验证码"
                          :rule="{rule: /\S/}"
                          @keyupEnter="phoneLogin"
                          @clear="phoneForm.code = $event">
                              <template #icon>
                                  <a-icon type="message" />
                              </template>
                          </uInput>
                          
                          <button :class="!phoneForm.switch && 'verify-code'" :disabled="phoneForm.switch" @click="getCode">{{phoneForm.count === 60 ? '获取验证码' : `${phoneForm.count}S`}}</button>
                        </div>

                        <div class="login-error">
                            <span v-if="phoneForm.error">{{phoneForm.error}}</span>
                        </div>
                        <uButton style="width: 100%" @click.native="phoneLogin">登录</uButton>
                    </div>
                    <!-- 密码登录 -->
                    <div class="login">
                        <uInput
                        name="username"
                        class="login-input"
                        :pwdSign="true"
                        v-model="pwdForm.username"
                        :max="11"
                        placeholder="账号名称/手机号码"
                        :rule="{rule: /\S/}"
                        @clear="pwdForm.username = $event">
                            <template #icon>
                                <a-icon type="user" />
                            </template>
                        </uInput>

                        <uInput
                        class="login-input"
                        type="password"
                        :pwdSign="true"
                        max="20"
                        v-model="pwdForm.pwd"
                        placeholder="输入登录密码"
                        :rule="{rule: /\S/}"
                        @keyupEnter="pwdLogin"
                        @clear="pwdForm.pwd = $event">
                            <template #icon>
                                <a-icon type="lock" />
                            </template>
                        </uInput>

                        <div class="login-error">
                            <span v-if="pwdForm.error">{{pwdForm.error}}</span>
                        </div>
                    <uButton style="width: 100%" @click.native="pwdLogin">登录</uButton>
                    </div>
                </div>

                <div class="user-operation">
                    <router-link to="/forgotPwd">忘记密码?</router-link>
                    <router-link to="/register">新用户注册</router-link>
                </div>

                <!-- <a-divider class="other">其他登录方式</a-divider> -->
              </div>
          </div>
        </div>
      </div>

      <FootMain />
  </div>
</template>

<script>
import NoLoginNav from '@/layout/NoLoginNav'
import FootMain from '@/layout/FootMain'
import uInput from '@/components/uInput'
import uButton from '@/components/uButton'
import {login, sendSms, phonneLogin} from '@/api/user.js'
import md5 from 'js-md5'
import cookie from 'js-cookie'

export default {
  components: {
    NoLoginNav,
    FootMain,
    uInput,
    uButton
  },
  data () {
    return {
      switchList: ['手机号登录', '账户密码登录'],
      // 登录切换
      active: 0,
      // 密码登录  
      pwdForm: {
          username: '',
          pwd: '',
          error: ''
      },
      // 验证码登录  
      phoneForm: {
          phone: '',
          code: '',
          error: '',
          switch: false,
          count: 60
      }
    }
  },
  methods: {
      pwdLogin() {
        let verify = true
        // 验证input框
        this.$children.forEach((item) => {
          let test
          item.$attrs.pwdSign && !( test = item.verifyRule()) && (verify = verify && test)
        })
        if (!this.pwdForm.username) {
          this.pwdForm.error = '请输入用户昵称'
        } else if (!this.pwdForm.pwd) {
          this.pwdForm.error = '请输入密码'
        }
        console.log(verify)
        if (!verify) return
        login({...this.pwdForm,pwd: md5(this.pwdForm.pwd), error: undefined}).then(res => {
          if (res.code === 200) {
            this.$message.success(res.message)
            this.$store.commit('logout')
            cookie.set('token', res.data.tokenHead + res.data.token)
            cookie.set('username', res.data.username)
            this.$router.push('/')
          } else {
            this.$message.error(res.message)
          }
        })
      },
      phoneLogin() {
        let verify = true
        // 验证input框
        this.$children.forEach((item) => {
          let test
          item.$attrs.phoneSign && !( test = item.verifyRule()) && (verify = verify && test)
        })
        if (!this.phoneForm.phone) {
          this.phoneForm.error = '请输入用户手机号'
        } else if (!(/^[1][3,4,5, 6,7,8,9][0-9]{9}$/.test(this.phoneForm.phone))) {
          this.phoneForm.error = '请输入正确的手机号码'
        } else if (!this.phoneForm.code) {
          this.phoneForm.error = '请输入验证码'
        }
        if (!verify) return
        phonneLogin({smsCode: this.phoneForm.code, phone: this.phoneForm.phone}).then(res => {
          if (res.code === 200) {
            this.$message.success(res.message)
            this.$store.commit('logout')
            cookie.set('token', res.data.tokenHead + res.data.token)
            cookie.set('username', res.data.username)
            this.$router.push('/')
          } else {
            this.$message.error(res.message)
          }
        })
      },
      getCode() {
        if (!(/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(this.phoneForm.phone))) {
          this.phoneForm.error = '请输入正确的手机号码'
        }
        if (this.$refs.code.verifyRule() && !this.phoneForm.switch) {
          sendSms(this.phoneForm.phone).then(res => {
            if (res.code === 200) {
              this.phoneForm.switch = true
              this.countDown()
            } else {
              this.$message.error(res.message)
            }
          })
        }
      },
      countDown() {
        if (this.phoneForm.count > 0) {
          this.phoneForm.count--
          setTimeout(this.countDown, 1000)
        } else {
          this.phoneForm.switch = false
          this.phoneForm.count = 60
        }
      },
  }
}
</script>

<style lang="less" scoped>
.container {
    width: 100%;
    min-width: 990px;
    height: 700px;
    background: url('../../assets/images/login.jpg') center center / cover no-repeat;
    position: relative;
    .user {
      position: relative;
      // width: 990px;
      max-width: 1440px;
      min-width: 990px;
      height: 100%;
      margin: 0 auto;
    }
    .user-active {
        position: absolute;
        top: 50%;
        right: 0;
        @media screen and (max-width: 1440px){
          right: 60px;
        }
        transform: translateY(-50%);
        background: #fff;
        width: 400px;
        height: 380px;
        padding: 8px 30px;
        overflow: hidden;
        .switch-login {
            display: flex;
            justify-content: space-between;
            div {
                font-size: 16px;
                width: 136px;
                line-height: 46px;
                text-align: center;
                color: rgba(0, 0, 0, 0.6);
                cursor: pointer;
            }
            .active {
                color: @primary;
                border-bottom: 2px solid @primary;
            }
        }
        .login-content {
          padding: 0 10px;
          overflow: hidden;
        }
        .login-container {
            padding-top: 30px;
            display: flex;
            transition: all .3s;
            .login {
                min-width: 100%;
                .login-error {
                    height: 50px;
                    line-height: 50px;
                    color: @error;
                }
                .login-input {
                    width: 100%;
                    &:first-child {
                        margin-bottom: 20px;
                    }
                }
                .input-code {
                  display: flex;
                  .code {
                    width: 204px;
                    margin-right: 10px;
                  }
                  button {
                    width: 106px;
                    height: 50px;
                    background: #fff;
                    cursor: pointer;
                    border: 1px solid @border;
                    font-size: 14px;
                  }
                  .verify-code {
                    &:hover {
                      border: 1px solid @primary;
                    }
                  }
                }
                &:first-child {
                    margin-right: 40px;
                }
            }
        }
        // 账号操作
        .user-operation {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 36px;
        }
        // 其他登录方式
        .other {
            font-size: 14px;
            color: #999999;
        }
    }
}
a {
    font-size: 12px;
}
</style>
