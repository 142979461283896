export const type = {
  T: '吨',
  K: '千克',
  G: '克',
  S: '台',
  C: '件'
}

export const invoiceState = {
  WAIT_APPLY: "等待申请开票",
  WAIT_AUDIT: '待审核',
  REFUSED: "已拒绝",
  REVIEWED: '已审核',
  PROCESSING: "开票中",
  WAIT_INVOICE: "等待开票",
  INVOICED: "已开票",
}
export const name = {
  'REGISTERED': '已注册',
  'CANCELAPPLIED': '申请注销',
  'APPLYING': '申请中',
  'CANCELLED': '已注销',
}
export const politics = [
  '群众',
  '中共党员',
  '共青团员',
  '民革党员',
  '民盟盟员',
  '民建会员',
  '民进会员',
  '农工党党员',
  '致公党党员',
  '九三学社社员',
  '台盟盟员',
  '无党派人士'
]

export const education = [
  '博士', '研究生', '本科', '大专', '中专', '高中', '初中', '小学', '半文盲'
]

export const transType = {
  'TRUCK': '货车',
  'TRICYCLE': '三轮车',
  'SHIP': '船',
  'AGRICULTURAL_VEHICLE': '农用车',
  'OTHER': '其他',
}

export const logisticsType = {
  'LICENSE_PLATE_PHOTO': '车牌照',
  'FULL_LOAD_WEIGHED_PHOTO': '满载过磅',
  'EMPTY_CAR_WEIGHED_PHOTO': '空车过磅',
  'ARRIVAL_PHOTO': '到达照',
  'LEFT_PHOTO': '出场照',
  'QUALITY_INSPECTION_PHOTO': '质检照',
}

export const channel={
  "ZS":'浙商',
  "PA":'平安',
}

export const invoiceType = {
  1: '电子专票',
  2: '电子普票',
  3: '纸质专票',
  4: '纸质普票'
}

export const paTallyType = {
  1: '会员支付',
  2: '会员冻结',
  3: '会员解冻',
  4: '登记挂账',
  6: '下单预支付',
  7: '确认并付款',
  8: '会员退款',
  22: '见证+收单平台调账',
  23: '见证+收单资金冻结',
  24: '见证+收单资金解冻',
  25: '会员间交易退款'
}

export const orderFundType  = {
  0: '未支付',
  1: '支付处理中',
  2: '已支付',
  3: '支付失败',
  4: '待确认付款',
  5: '全部解冻',
  6: '提现处理中',
  7: '全部提现',
  8: '提现失败',
  9: '部分提现',
  10: '解冻处理中',
  11: '部分解冻',
  12: '解冻失败',
  13: '税款提现成功，货款提现处理中',
  14: '货款提现成功，税款提现处理中',
  15: '税款提现失败，货款提现成功',
  16: '货款提现失败，税款提现成功'
}

export const tradeType = {
  'TOP_UP': '充值',
  'ORDER_TRADE': '交易',
  'GROSS_TAX_WITHDRAW': '提现'
}

export const tradeStatus = {
  'SUCCESS': '到账成功',
  'FAILURE': '到账失败',
  'PROCESSING': '处理中'
}

export const batchStatus = {
  'WAIT': '等待处理',
  'PROCESSING': '处理中',
  'FAIL': '失败',
  'PART_FAIL': '部分失败',
  'SUCCESS': '成功'
}

export const dsStatusType={
  "ENABLE":'启用',
  "DISABLE":'禁用',
}