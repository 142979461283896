<template>
  <div class="tax">
    <div class="tax-context">
      <div class="tax-list">
        <div v-for="(item,index) in taxList" :key="index" class="tax-item">
          <a :href="item.url" class="tax-title" target="_blank">
            <a-icon type="file-text" style="font-size:30px;color: #bfbf00;margin-right:8px"/>
            <div>{{item.title}}</div>
          </a>
          <div class="tax-msg">{{item.msg}}</div>
          <a :href="item.url" class="tax-goweb" target="_blank">阅读详情>></a>
        </div>
      </div>
      <div class="scheme">
        <img src="../../assets/images/tax.png" alt="" width="440">
        <div class="scheme-right">
          <div class="scheme-title">再生资源行业综合解决方案</div>
          <div class="scheme-context">针对再生资源行业困境及相关政策，依托于91再生10多年的行业经验，提供再生资源行业合规交易的解决方案</div>
          <div class="scheme-item" v-for="(item,index) in schemeList" :key="index">
            <div class="se-title"><a-icon type="check-circle" class="se-icon"/>{{item.title}}</div>
            <div class="se-msg">{{item.context}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default{
    name:'TaxInfo',
    data(){
      return {
        taxList:[{
            title:'政策解读！财税40号文《关于完善资源综合利用增值税政策的公告》',
            msg:'2021年的最后一天，财政部发布了《关于完善资源综合利用增值税政策的公告》（财政部税务总局公告2021年第40号）。这给产业带来重大利好的同时，也引发了大家的广泛关注与思考。',
            url:'https://mp.weixin.qq.com/s/sorHZ20tf43_uVcue16BNg'
          },
          {
            title:'增值税小规模纳税人减免增值税等政策有关征管事项明确（附解读）',
            msg:'2023年1月9日，财政部、税务总局制发了2023年第1号文件《财政部 税务总局关于明确增值税小规模纳税人减免增值税等政策的公告》，我们有哪些解读那？',
            url:'https://mp.weixin.qq.com/s/AtxhMb7DafV5Ul1ju7ER6A'
          },
          {
            title:'案例讲解！资源综合利用企业如何计算即征即退税额',
            msg:'自40号公告发布以来，91再生在线上举办了多场线上直播解读活动，直播活动中，也收到了一些咨询： 如何计算即征即退税额？收到的退税是否缴纳企业所得税？收到的财政补贴收入是否缴税？',
            url:'https://mp.weixin.qq.com/s/Futthnf9P8i0Xl-7lM4tXQ'
          },
        ],
        schemeList:[
          {
            title:'交易合规',
            context:'交易双方均进行资质验证，同时对其成交单价进行有效评估，杜绝脱离市场行情'
          },
          {
            title:'支付合规',
            context:'解决大额转账支付问题，公对私在央行监管下进行合规转账'
          },
          {
            title:'订单存证',
            context:'回收订单信息化，存证永久留存，可以实时进行查询比对'
          },
          {
            title:'交易真实性',
            context:'订单进行五流记录与监管，通过技术手段确保业务的真实性'
          }
        ]
      }
    }
  }
</script>
<style lang="less" scoped>
  .tax {
    background-color: #fff;
    min-width: 990px;
    .tax-context{
      max-width: 1440px;
      min-width: 990px;
      margin: 0 auto;
      padding: 80px 0;
      .tax-list{
        display: flex;
        justify-content: space-between;
        padding: 0 35px;
        .tax-item{
          background-color: #f2f2f2;
          width: 350px;
          height: 200px;
          padding: 25px ;
          position: relative;
          .tax-title{
            font-weight: bold;
            font-size: 16px;
            color: #666666;
            line-height: 20px;
            display: flex;
            &:hover{
              color: #bfbf00;
            }
          }
          .tax-msg{
            color: #666666;
            font-size: 14px;
            line-height: 18px;
            margin-top: 15px;
          }
          .tax-goweb{
            // float: right;
            position:absolute;
            right:20px;
            bottom:20px;
            color: #b8741a;
            &:hover{
              text-decoration: underline;
            }
          }
        }
      }
      .scheme{
        display: flex;
        justify-content: space-between;
        padding: 40px 80px 0;
        color: #666;
        .scheme-right{
          width: 540px;
        }
        .scheme-title{
          font-size: 24px;
          font-weight: bold;
          margin-top: 40px;
        }
        .scheme-context{
          font-size: 14px;
          
          font-style: italic;
          line-height: 20px;
          margin-top: 15px;
        }
        .scheme-item{
          padding-left: 30px;
          margin-top: 25px;
          .se-title{
            font-size: 14px;
            font-weight: bold;
            position: relative;
            .se-icon{
              color: #6dba44;
              position: absolute;
              left: -30px;
              top:-3px
            }
          }
          .se-msg{
            font-size: 14px;
            margin-top: 10px;
            line-height: 1.2;
          }
        }
      }
    }
  }
</style>