<template>
  <div class="main">
    <div class="chunk table-left">
      <div class="header">
        再生资源商品上新
      </div>
      <div class="info" @mouseover="Stop()" @mouseleave="ScrollUp()">
        <a-row class="head-title">
          <a-col :span="7">商品名称</a-col>
          <a-col :span="7" align="middle">供应商</a-col>
          <a-col :span="5" align="right">单价</a-col>
          <a-col :span="5" align="right">库存</a-col>
        </a-row>
        <div class="right-info" ref="fresh">
            <a-row v-for="(i) in fresh.list" :key="i.id" :gutter="6">
              <a-col class="beyond_eip" :span="7" :title="i.mdseName">{{i.mdseName}}</a-col>
              <a-col class="beyond_eip" :span="7" align="middle" :title="i.supplierName">{{i.supplierName}}</a-col>
              <a-col align="right" class="beyond_eip" :span="5" :title="`${i.unitPrice.toFixed(2)}元/${i.unit}`">
                {{i.unitPrice.toFixed(2)}}元/{{i.unit}}</a-col>
              <a-col align="right" class="beyond_eip" :span="5" :title="`${i.quantityAndMeasure.toFixed(i.unit === '件' || i.unit === '台' ? 0 : 4)}${i.unit}`">
                {{i.quantityAndMeasure.toFixed(i.unit === '件' || i.unit === '台' ? 0 : 4)}}{{i.unit}}</a-col>
            </a-row>
        </div>
      </div>
    </div>
    <div class="chunk table-right">
      <div class="header">
        近期订单
      </div>
      <div class="info" @mouseover="Stop('order')" @mouseleave="ScrollUp('order')">
        <a-row class="head-title">
          <a-col :span="4">商品名称</a-col>
          <a-col :span="4" align="middle">发票品类</a-col>
          <a-col :span="4" align="middle">供应商</a-col>
          <a-col :span="5" align="middle">采购商</a-col>
          <a-col :span="3" align="right">数量</a-col>
          <a-col :span="4" align="middle">订单日期</a-col>
        </a-row>
        <div class="right-info" ref="order" id="el-right-table-order">
            <a-row v-for="i in order.list" :key="i.id" :gutter="6">
              <a-col class="beyond_eip" :span="4" :title="i.mdseName">{{i.mdseName}}</a-col>
              <a-col class="beyond_eip" :span="4" align="middle" :title="i.invoiceName">{{i.invoiceName}}</a-col>
              <a-col class="beyond_eip" :span="4" align="middle" :title="i.supplierName">{{i.supplierName}}</a-col>
              <a-col class="beyond_eip" :span="5" align="middle" :title="i.commercialName">{{i.commercialName}}</a-col>
              <a-col class="beyond_eip" :span="3" align="right" :title="i.quantity">{{i.quantity}}</a-col>
              <a-col class="beyond_eip" :span="4" align="middle" :title="i.orderDate">{{i.orderDate}}</a-col>
            </a-row>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { newest, recentOrder } from '@/api/public'
import { type } from "@/utils/constArr";
// import ElementResizeDetectorMaker from "element-resize-detector"
import cookie from '@/utils/js-cookie.js'
export default {
  // components:{
  //   MdTitle
  // }
  data() {
    return {
      goodsList: [],
      fresh: {
        list: [],
        scrollTop: 0,
        interval: null,
        viewHeight: 0
      },
      order: {
        list: [],
        scrollTop: 0,
        interval: null,
        viewHeight: 0
      },
      checkEntity: cookie.get('checkEntity') || {},
      erd: null
    }
  },
  mounted() {
    newest().then(res => {
      res.data && res.data.forEach(item => {
          item.unit = item.quantityAndMeasure && this.strUnit(item.quantityAndMeasure) || ''
          item.quantityAndMeasure = item.quantityAndMeasure && this.str(item.quantityAndMeasure) || ''
      })
      if (res.data.length < 7) {
        this.fresh.list = res.data
        return
      }
      this.fresh.list = [...res.data, ...res.data]
      this.$nextTick(() => {
          console.log(this.$refs.fresh.scrollHeight);
        this.fresh.viewHeight = this.$refs.fresh.scrollHeight / 2
        this.ScrollUp()
      })
    })
    recentOrder().then(res => {
      res.data && res.data.forEach(item => {
          item.quantity = item.quantity && this.strRep(item.quantity) || ''
      })
      if (res.data.length < 7) {
        this.order.list = res.data
        return
      }
      this.order.list = [...res.data, ...res.data]
      this.$nextTick(() => {
        this.order.viewHeight = this.$refs.order.scrollHeight / 2
        this.ScrollUp('order')
      })
    })
    // this.erd = ElementResizeDetectorMaker()
    // this.erd.removeListener(document.getElementById("el-right-table-order"),this.callback)
    // this.erd.listenTo(document.getElementById("el-right-table-order"),this.callback)
  },
  beforeDestroy() {
    this.Stop()
    this.Stop('order')
  },
  methods: {
    str(val) {
        // 获取除了最后一位的值
      let length = val.length
      let newArr = val.split('')
      newArr.splice(length - 1, 1)
      return Number(newArr.join(''))
    },
    strUnit(val) {
        // 获取最后一位的值
      let length = val.length
      let newArr = val.split('')
      return type[newArr[length - 1]]
    },
    strRep(val) {
      let length = val.length
      let newArr = val.split('')
      newArr[length - 1] = type[newArr[length - 1]]
      return newArr.join('')
    },
    callback(e) {
      console.log(222, e.offsetHeight);
      this.order.viewHeight = e.clientHeight / 2
    },
    ScrollUp(str = 'fresh') {
      if (!this[str].viewHeight) {
        return
      }
      this.Stop(str)
      this[str].interval = setInterval(() => {
        if (this.$refs[str].scrollTop == this[str].viewHeight) {
          this.$refs[str].scrollTop = 0;
        } else {
          this.$refs[str].scrollTop++
        }
      }, 100);
    },
    clicks(i) {
        console.log(11);
        this.$router.push(`/product-details?id=${i.id}`)
    },
    Stop(str = 'fresh') {
      clearInterval(this[str].interval);
    },
    Up() {
      this.ScrollUp();
    }
  }
}
</script>
<style lang="less" scoped>
.main {
  display: flex;
  max-width: 1440px;
  min-width: 990px;
  .chunk {
    border-width: 1px;
    border-style: solid;
    border-color: rgba(215, 215, 215, 1);
    background: #fff;
    .header {
      background: #54ac86;
      height: 40px;
      line-height: 40px;
      color: #fff;
      font-weight: 700;
      font-size: 14px;
      text-align: center;
    }
  }
  .info {
    padding: 0 12px 12px;
    margin-bottom: 12px;
    width: 100%;
    height: 206px;
    overflow: hidden;
    position: relative;
  }
  .head-title {
    font-weight: 700;
    line-height: 24px;
    font-size: 14px;
  }
  .table-left {
    width: 400px;
    margin-right: 32px;
  }
  .table-right {
    width: calc(100% - 432px);
  }
  .right-info {
    height: 100%;
    overflow: hidden;
    /deep/ .ant-row {
      cursor: pointer;
      &:hover {
        color: @primary;
      }
    }
    /deep/ .ant-col {
      font-size: 12px;
      line-height: 32px;
    }
  }
}
</style>