<template>
  <div class="search-wrapper">
    <div class="search-main" :class="!(hasLogin && userText) && 'dom'">
      <div class="search-left ">
        <div>
          <router-link to="/"><img src="../../assets/images/logo2.png" /></router-link>
          <div class="web-title">专业合规的再生资源交易平台</div>
        </div>
        <div class="search-right l-flex-aligncenter">
          <router-link :to="item.route" v-for="(item,index) in tabs" class="tab" :class="item.route === $route.path && 'tab-active'" :key="index">
            {{item.name}}
          </router-link>
        </div>
      </div>
      <div class="switch-status l-flex-aligncenter" v-if="hasLogin && userText">
        <a-space>
          <div class="btn" @click="to()">{{userText}}</div>
          <div v-if="checkEntity.entityCharacter === 'P'" class="problem problem-shop buy-shop" @click="$router.push('/cart')">
            <div class="num">{{$store.state.cartAmount}}</div>
          </div>
        </a-space>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState,mapGetters} from 'vuex'
import cookie from '@/utils/js-cookie.js'
export default {
  data(){
    return {
      searchType:[
        {label:'商品',key:'0',placeholder:'输入产品关键字，搜索相应商品'},
        // {label:'店铺',key:'1',placeholder:'输入店铺或者个人名称，搜索相应店铺'},
        {label:'发货地',key:'1',placeholder:'输入省份名称，搜索该发货地和商品'}
      ],
      activeType:this.$route.query.address !== undefined ? '1' : '0',
      searchText:this.$route.query.product || this.$route.query.address || '',
      checkEntity: cookie.get('checkEntity'),
      tabs: [
        {name: '首页', route: '/index'},
        {name: '交易中心', route: '/product-list'},
        {name: '产品介绍', route: '/products'},
        {name: '解决方案', route: '/solution'},
        // {name: '交易中心', route: '/product-list?product='},
        {name: '申请入驻', route: '/application'},
        // {name: '交易中心', route: '/product-list?product='},
        // {name: '申请入驻', route: '/application'},
      ]
    }
  },
  watch: {
    '$route'(val) {
      this.searchText = val.query.product || val.query.address || ''
    }
  },
  computed:{
    ...mapState(['userMenu','checkId', 'hasLogin']),
    ...mapGetters(['getRoleS', 'getRoleP']),
    searchUrl(){
      switch(this.activeType){
        case "0":
          return `/product-list?product=${this.searchText}`
        case "1":
          return `/product-list?address=${this.searchText}`
        case "2":
          return `/product-list?address=${this.searchText}`
      }
      
      return 1
    },
    userText() {
      let obj = {}
      if (!this.checkEntity) {
        return ''
      } else if (this.checkEntity.entityCharacter === 'P') {
        obj = this.getRoleP.find(item => item.entityId == this.checkEntity.entityId)
        return obj ? '买家：' + obj.commercialName + `（${obj.authRoleName}）` : ''
      } else if (this.checkEntity.entityCharacter === 'S') {
        obj = this.getRoleS.find(item => item.entityId == this.checkEntity.entityId)
        return obj ? '卖家：' + obj.commercialName + `（${obj.authRoleName}）` : ''
      } else {
        cookie.remove('checkEntity')
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.checkEntity = ''
      }
      return ''
    },
  },
  async mounted() {
    cookie.get('token') && cookie.get('username') && !this.userMenu.length && await this.$store.dispatch('getuserMenu')
    this.init()
  },
  methods: {
    username() {
      return cookie.get('token') && cookie.get('username')
    },
    to() {
      if (this.checkEntity.entityCharacter === 'S') {
        this.$router.push('/switch-seller')
      } else {
        this.$router.push('/switch-buyer')
      }
    },
    init() {
      if(!this.checkEntity && cookie.get('token') && cookie.get('username') && this.userMenu.length) {
        const defaultVal = this.getRoleP[0] || this.getRoleS[0]
        cookie.set('checkEntity', {entityCharacter: defaultVal.entityCharacter, entityId: defaultVal.entityId})
        this.checkEntity = {entityCharacter: defaultVal.entityCharacter, entityId: defaultVal.entityId}
      }
      // 获取购物车数量
      this.$store.dispatch('cartAmount')
    },
  }
};
</script>

<style lang="less" scoped>
.search-wrapper {
  min-width: 990px;
  background-color: #fff;
  margin: 0 auto;
  .dom {
      &::after {
        content: '';
        width: 300px;
        display: inline-block;
      }
    }
  .search-main {
    margin: 0 auto;
    max-width: 1440px;
    min-width: 990px;
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
    @media screen and(max-width:1440px) {
      padding-left:15px;
      padding-right: 15px;
    }
    .search-left {
      display: flex;
      img {
        width: 200px;
      }
    }
    .switch-status {
      // margin-top: 26px;
      .btn {
        padding: 2px 6px;
        width: 300px;
        min-height: 30px;
        line-height: 14px;
        font-weight: 700;
        text-align: center;
        font-size: 14px;
        border: 1px solid @primary;
        color: @primary;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
    .web-title {
      font-size: 14px;
      height: 18px;
      color: #777777;
      line-height: 17px;
      border: 1px solid #cbcbcb;
      border-radius: 9px;
      text-align: center;
      margin-top: 10px;
      padding: 0 8px;
    }
    .search-right {
      margin-left: 60px;
      @media screen and (max-width: 1080px){
        margin-left: 10px;
      }
      .tab {
        color: rgba(0, 0, 0, 0.65);
        cursor: pointer;
        user-select: none;
        display: inline-block;
        font-size: 16px;
        line-height: 42px;
        font-weight: 700;
        padding: 0 16px;
        text-align: center;
        font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑', sans-serif;
        position: relative;
        &:hover {
        color: #bfbf00;
          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 80%;
            height: 2px;
            background: #bfbf00;
            margin: 0 10%;
          }
        }
      }
      .tab-active {
        color: #b8741a !important;
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 80%;
          height: 2px;
          background: #b8741a !important;
          margin: 0 10%;
        }
      }
      .type-tab {
        color: #e6e6e6;
        margin-bottom: 6px;
        margin-left: 6px;
        .type-tab-item {
          display: inline-block;
          padding: 0 5px;
          margin: 0 10px;
          height: 16px;
          text-align: center;
          line-height: 16px;
          vertical-align: middle;
          color: #606060;
          cursor: pointer;
          position: relative;
          &:last-child::after{
            display: none;
          }
          &::after{
            position: absolute;
            content: '';
            height: 70%;
            top: 15%;
            width: 1px;
            background-color: #606060;
            right: -10px;
          }
          &.tab-active{
            color: #fff;
            background: #73bc42;
          }
        }
      }
      .search-ipt-box{
        border: 2px solid #60a830;
        width: 492px;
        @media screen and(max-width:1200px) {
          width: 400px;
        }
        .active-type{
          height: 40px;
          display: flex;
          width: 90px;
          justify-content: center;
          align-items: center;
          border-right:1px solid @border;
          position: relative;
          display: none;
          &:hover{
            .down-type-menu{
              display: block;
            }
          }
          .down-type-menu{
            display: none;
            position: absolute;
            top: 40px;
            width: 100%;
            border: 1px solid @border;
            border-top: 0;
            background-color: #fff;
            .dt-item{
              height: 30px;
              text-align: center;
              line-height: 30px;
              z-index: 30;
              cursor: pointer;
              &:hover{
                color: @primary;
              }
            }
          }
          .icon-down{
            position: absolute;
            right: 8px;
          }
        }
        .search-ipt {
          width: 388px;
          line-height: 16px;
          padding: 10px 10px;
          outline: none;
          @media screen and(max-width:1200px) {
            width: 296px;
          }
        }
        .search-btn {
          border: 0;
          width: 100px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          background: #60a830;
          font-size: 16px;
          color: #fff;
          cursor: pointer;
          &:active{
            opacity: 0.8;
          }
        }
      }
    }
  }
}
.modal {
  p {
    width: 80%;
    margin-bottom: 12px;
    font-size: 16px;
  }
  .content {
    display: flex;
    flex-wrap: wrap;
  }
  .btn {
    border-radius: 4px;
    border: 1px solid #e4e4e4;
    color: #999999;
    width: 31%;
    min-width: 31%;
    margin-right: 3%;
    display: flex;
    align-items:center;
    justify-content:center;
    padding: 0 6px;
    height: 35px;
    line-height: 16px;
    text-align: center;
    margin-bottom: 12px;
    cursor: pointer;
    &:nth-child(3n) {
      margin-right: 0;
    }
    &:hover {
      color: @primary;
      border-color: @primary;
    }
  }
  .check {
    color: @primary;
    border-color: @primary;
  }
}
.buy-shop {
  cursor: pointer;
  font-size: 28px;
  color: #B8741A;
  position: relative;
  .num {
    position: absolute;
    right: -5px;
    top: -5px;
    font-size: 12px;
    border-radius: 50%;
    border: 1px solid #bbb7b1;
    width: 16px;
    height: 16px;
    background: #fff;
    text-align: center;
    line-height: 16px;
  }
}
</style>
