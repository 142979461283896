<template>
  <div class="cnt-right">
    <div class="user-go">
      <template v-if="!username()">
        <router-link class="user-go-item" to="/login"><a-icon type="user" class="icon-use-go"/>登录</router-link>
        <router-link class="user-go-item" to="/register"><a-icon type="solution" class="icon-use-go"/>注册</router-link>
      </template>
      <template v-else>
        <a class="user-go-item user-show"><a-icon type="user" class="icon-use-go" style="color: #4b7902"/>{{username()}}</a>
      </template>
    </div>
    <div class="other-link">
      <template v-if="username()">
        <div class="other-link-item"><i></i><router-link to="/account-center/settings">账号管理</router-link></div>
        <div class="other-link-item"><i></i><router-link to="/buyer">买家入驻</router-link></div>
        <!-- <div class="other-link-item"><i></i>我的订单</div> -->
        <div class="other-link-item"><i></i><span @click="logout">退出登录</span></div>
      </template>
      <template v-else>
        <div class="other-link-item"><i></i><router-link to="/about">平台介绍</router-link></div>
        <div class="other-link-item"><i></i><router-link to="/register">注册登录</router-link></div>
        <div class="other-link-item"><i></i><router-link to="/application">申请入驻</router-link></div>
      </template>
    </div>

    <!-- 最新通知 -->
    <div class="news">
      <div class="n-title">最新通知</div>
      <div>
        <ul class="new-list">
          <li>
            2023年1月1日起，订单五流存证内容依据国家税务政策及供应商所在地税务机关执行标准进行动态调整，请依据要求进行补充完善！
          </li>
          <!-- <li>根据财税2022年第15号文规定，小规模纳税人可以享受免征增值税优惠政策。</li> -->
          <!-- <li>平台最新通知，平台最新通知</li>
          <li>平台最新通知，平台最新通知</li> -->
        </ul>
      </div>
      <!-- <div class="go-detail">查看详情</div> -->
    </div>

    <!-- 公告栏 -->
    <div class="callboard">
      <div class="n-title">公告栏</div>
      <div class="cb-list">
        <ul>
          <li title="关于明确增值税小规模纳税人减免增值税等政策的公告"><router-link to="/notice-details/stop">关于明确增值税小规模纳税人减免...</router-link></li>
          <li title="91再生交易平台上线啦！"><router-link to="/notice-details/s1">91再生交易平台上线啦！</router-link></li>
          <!-- <li title="增值税1%征收率即将结束"><router-link to="/notice-details/s2">增值税1%征收率即将结束</router-link></li> -->
          <li title="91再生交易平台执行“实名认证”入驻"><router-link to="/notice-details/s3">91再生交易平台执行“实名认证”入驻</router-link></li>
          <!-- <li title="关于个体工商户发票存证限额的通知"><router-link to="/notice-details/s4">关于个体工商户发票存证限额的通知</router-link></li> -->
          <li title="91再生网依法进行主体登记，履行纳税义务"><router-link to="/notice-details/s5">91再生网依法进行主体登记，履行纳税义务</router-link></li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
import cookie from '@/utils/js-cookie.js'
export default {
  data(){
    return {
      scrollTop:0,
      newsList:[1,2]
    }
  },
  computed: {
    top() {
      return -this.activeIndex * 20 + 'px';
    },
    ...mapGetters(['userStatus'])
  },
  mounted() {
    // this.moveViewHeight = this.$refs.moveView.offsetHeight
    // this.newsList = [...this.newsList,...this.newsList]; //复制一个列表到原先的列表底部
    // this.ScrollUp()
  },
  methods: {
    username() {
      return cookie.get('token') && cookie.get('username')
    },
    logout() {
      this.$store.commit('logout')
      this.$router.push('/login')
    },
    ScrollUp() {
      // eslint-disable-next-line no-unused-vars
      this.intnum = setInterval(_ => {
        if (this.scrollTop == this.moveViewHeight) {
          this.scrollTop = 0;
        } else {
          this.scrollTop += 1;
        }
      }, 100);
    },
    Stop() {
      clearInterval(this.intnum);
    },
    Up() {
      this.ScrollUp();
    }
  },
  destroyed(){
    clearInterval(this.intnum);
  }
}
</script>
<style lang="less" scoped>
.cnt-right {
  width: 300px;
  height: 505px;
  background: #fff;
  @media screen and (max-width:1200px) {
      // left: 250px;
    width: 280px;
  }
  .user-go {
    display: flex;
    .user-show {
      color: #4b7902 !important;
      font-weight: bold;
    }
    .user-go-item {
      flex: 1;
      justify-content: center;
      align-items: center;
      display: flex;
      border: 1px solid #e6e6e6;
      height: 50px;
      color: #333;
      .icon-use-go{
        color:@primary;
        margin-right: 10px;
        font-size: 18px;
      }
      &:first-child {
        border-right: 0;
      }
    }
  }
  .other-link {
    display: flex;
    flex-wrap: wrap;
    padding: 5px 0;
    margin-bottom: 16px;
    .other-link-item{
      cursor: pointer;
      flex-basis: 50%;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      i{
        display: block;
        height: 4px;
        width: 4px;
        background-color: #ccc;
        border-radius: 50%;
        margin-right: 5px;
      }
      a {
        color: rgba(0, 0, 0, 0.65);
      }
    }
  }
  .news{
    margin-bottom: 24px;
    .new-list{
    margin: 0 15px;
      li{
        text-indent: 2em;
        line-height: 24px;
      }
    }
    .go-detail{
      text-align: right;
      color: @primary;
      margin-right: 15px;
    }
  }
  .cb-list{
    margin: 0 15px;
    height: 230px;
    overflow: hidden;
    li,a {
      color: rgba(0, 0, 0, 0.65);
      line-height: 28px;
    }
    a:hover {
      text-decoration: underline;
      color: #73bc42;
    }
    .cb-item{
      padding-bottom: 8px;
      .cb-name{
        background-color: #f2f2f2;
        padding: 3px 5px;
      }
      .cb-detail{
        margin-top: 5px;
        line-height: 16px;
      }
      .cb-foot{
        display: flex;
        justify-content: space-between;
        margin-top: 5px;
        .cb-link{
          color: @primary;
        }
        .cb-time{
          color: @error;
        }
      }
    }
  }
  
}
.n-title{
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  height: 36px;
  margin-bottom: 6px;
  &::after{
    height: 2px;
    width: 100px;
    background-color: @primary;
    content: '';
    display: block;
    margin: 0 auto;
    margin-top: 5px;
  }
}
</style>