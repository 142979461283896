<template>
  <div class="right-fiexd">
    <div class="right-item" v-if="btnFlag" @click="backTop">
      <a-icon type="up" :style="{ fontSize: '20px' }" />
      <div>返回顶部</div>
    </div>
    <div class="right-item">
      <a-icon type="message" :style="{ fontSize: '20px' }"/>
      <div>咨询反馈</div>
      <div class="wx-code">
        <img src="../../assets/images/qy-wx.png" alt="" width="122">
        <div>微信扫码咨询</div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data(){
      return {
        btnFlag:false
      }
    },
    mounted () {
        window.addEventListener('scroll', this.scrollToTop)
    },
    destroyed () {
        window.removeEventListener('scroll', this.scrollToTop)
    }, 
     methods: {
        // 点击图片回到顶部方法，加计时器是为了过渡顺滑
        backTop () {
            const that = this
            let timer = setInterval(() => {
                let ispeed = Math.floor(-that.scrollTop / 5)
                document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
                if (that.scrollTop === 0) {
                    clearInterval(timer)
                }
            }, 16)
        },
        
        // 为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于60则隐藏
        scrollToTop () {
            const that = this
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
                that.scrollTop = scrollTop
            if (that.scrollTop > 60) {
                that.btnFlag = true
            } else {
                that.btnFlag = false
            }
        }
    } 
  }
</script>
<style lang="less" scoped>
  .right-fiexd{
    position: fixed;
    bottom: 60px;
    right: 15px;
    z-index: 99;
    .right-item{
      width: 80px;
      height: 80px;
      background: inherit;
      background-color: rgba(255, 255, 255, 1);
      box-sizing: border-box;
      border-width: 1px;
      border-style: solid;
      border-color: rgba(242, 242, 242, 1);
      border-radius: 0px;
      box-shadow: none;
      line-height: 28px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 10px 0;
      position: relative;
      &:hover{
        color: #54ac86;
        cursor: pointer;
      }
      &:hover{
        .wx-code{
          display: flex;
        }
      }
      .wx-code{
        position: absolute;
        right: 100px;
        width:  150px;
        height: 180px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, .5);
        display: none;
        color: #666;
        font-weight: bold;
        // position: relative;
        &::after{
          position: absolute;
          content: '';
          width: 0;
          height: 0;
          border-top:10px solid transparent;
          border-left: 10px solid #fff;
          border-bottom: 10px solid transparent;
          right:-10px;
          filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, .5));
        }
        &::before{
          position: absolute;
          content: '';
          z-index: 2;
          width: 5px;
          height: 20px;
          background-color: #fff;
          right:0px;
        }
      }
    }
  }
</style>