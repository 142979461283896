import Vue from 'vue'
import Vuex from 'vuex'
import {menu} from '@/api/public.js'
import {cartAmount} from '@/api/cart'
// import storeLocal from 'storejs'
import cookie from '@/utils/js-cookie.js'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    data: 1,
    userMenu: [],
    checkId: 0,
    hasLogin: false,
    cartAmount: 0,
  },
  mutations: {
    logout(state) {
      cookie.remove('token')
      cookie.remove('username')
      cookie.remove('hasLogin')
      cookie.remove('checkEntity')
      state.userMenu = []
      state.checkId = 0
      state.hasLogin = false
		},
    saveUserMenu(state, userMenu) {
      state.userMenu = userMenu || []
      state.hasLogin = true;
      cookie.set('hasLogin', true)
		},
    checkUser(state, {entityId}) {
			state.checkId = entityId
		},
    cartAmount(state, count) {
      state.cartAmount = count
		},
  },
  actions: {
    async getuserMenu() {
      const res = await menu()
      // 405查不到数据
      if (res.code === 405 || res.code === 200) {
        this.commit('saveUserMenu', res.data)
      }
    },
    async cartAmount(state, useCache = 0) {
      const entityId = (cookie.get('checkEntity') || {}).entityId
      if (entityId) {
        const res = await cartAmount({entityId, useCache})
        // 405查不到数据
        if (res.code === 200) {
          this.commit('cartAmount', res.data)
        }
      }
    }
  },
  modules: {
  },
  getters: {
    // 卖家中心权限
    getRoleS(state) {
      const {roleVoList} = state.userMenu.find(item => item.entityCharacter === 'S') || {}
      return roleVoList || []
    },
    // 买家家中心权限
    getRoleP(state) {
      const {roleVoList} = state.userMenu.find(item => item.entityCharacter === 'P') || {}
      return roleVoList || []
    },
  }
})
