import axios from 'axios'
import { message } from 'ant-design-vue'
import store from '../store'
import router from '../router'
import cookie from '@/utils/js-cookie.js'
// import Qs from 'qs'
let baseURL = process.env.VUE_APP_API_BASE_URL
const apiServer = axios.create({
  baseURL: baseURL,
  timeout: 30000,
  headers: {
    contentType: 'application/json'
  }
})

apiServer.interceptors.request.use(config => {
  const token = cookie.get('token')
  if(token){
    config.headers['Authorization'] = token
  }
  return config
}, err => {
  return Promise.reject(err)
})

apiServer.interceptors.response.use(response => {
  const res = response.data
  if (res.code === 401 && res.message === '暂未登录或token已经过期') {
    store.commit('logout')
    if (router.history.current.meta.auth) {
      router.push('/login')
    }
  }
  return Promise.resolve(res)
}, err => {
  console.log("拦截器error",err);
  //网络超时异常处理
  if(err.code === 'ECONNABORTED' || err.message.includes("timeout")){  
    message.error('请检查网络连接是否正常')
  } else {
    message.error('请求接口异常')
  }
  return Promise.reject(err)
})

export default apiServer
