<template>
  <div class="cnt-rt">
    <div class="cnt-rt-title">
      行业分类<a-icon type="menu-unfold" class="icon-menu" />
    </div>
    <div class="cnt-rt-item" v-for="(item,index) in dataFilter" :key="item.id"  @mouseenter="onHandlerMouseeter(index)" @mouseleave="onHanderMouseleave">
      <div class="type-title">
        <i :class="item.catValue|iconFilters" class="inst-icon"></i>
        {{ item.catValue }}
      </div>
      <div class="type-child">
        <a v-for="(pro,indexC) in item.children" :title="pro.catValue" :key="pro.id" class="hot-type" :style="{display:indexC>2?'none':''}">
          {{pro.catValue}}
        </a>
      </div>
      <a-icon type="right" class="icon-right" />
    </div>

    <!-- 二级分类 -->
    <transition name="fade">
      <div class="prodt-list" v-if="isMenuPostShow" @mouseenter="onHandlerMouseeterMenu"  @mouseleave="onHanderMouseleave">
        <div class="prodt-item" v-for="(item,index) in dataFilter" :key="item.id" :class="{'hoverClass':hoverIndex==index}">
          <div class="clear_fix h-sidebar-box">
            <div class="h-sidebar-name fl">{{ item.catValue }} <a-icon type="right" class="icon-right"/></div>
            <div class="h-sidebar-moust">
              <router-link :to="'/product-list?product=' + pro.catValue" class="h-sidebar-link" v-for="(pro) in item.children" :key="pro.id">{{pro.catValue}}</router-link>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
const iconMap = {
  '废塑料':'inst-icon1',
  '废金属':'inst-icon2',
  '废纸':'inst-icon3',
  '废纺织品':'inst-icon4',
  '废电子电器':'inst-icon5',
  '废玻璃橡胶':'inst-icon6'
}

export default {
  props:{
    data:{
      type:Array,
      default:()=>[]
    }
  },
  computed:{
    dataFilter () {
      return this.data.length<7?this.data:this.data.slice(0,6)
    }
  },
  filters:{
    iconFilters(value){
      if(!value){
        return ''
      }
      return iconMap[value]||'inst-icon7'
    }
  },
  data() {
    return {
      industryLsit: [
        {
          iconName: "inst-icon1",
          label: "废塑料",
          key: 0,
          hotProduct: [
            { label: "ASB", key: 0 },
            { label: "PP", key: 1 },
            { label: "PE", key: 2 },
            { label: "PVE颗粒", key: 3 },
          ],
        },
        {
          iconName: "inst-icon2",
          label: "废金属",
          key: 1,
          hotProduct: [
            { label: "ASB", key: 0 },
            { label: "PP", key: 1 },
            { label: "PE", key: 2 },
            { label: "PVE颗粒", key: 3 },
          ],
        },
        {
          iconName: "inst-icon3",
          label: "废纸",
          key: 2,
          hotProduct: [
            { label: "ASB", key: 0 },
            { label: "PP", key: 1 },
            { label: "PE", key: 2 },
            { label: "PVE颗粒", key: 3 },
          ],
        },
        {
          iconName: "inst-icon4",
          label: "废纺织品",
          key: 3,
          hotProduct: [
            { label: "ASB", key: 0 },
            { label: "PP", key: 1 },
            { label: "PE", key: 2 },
            { label: "PVE颗粒", key: 3 },
          ],
        },
        {
          iconName: "inst-icon5",
          label: "废电子电器",
          key: 4,
          hotProduct: [
            { label: "ASB", key: 0 },
            { label: "PP", key: 1 },
            { label: "PE", key: 2 },
            { label: "PVE颗粒", key: 3 },
          ],
        },
        {
          iconName: "inst-icon6",
          label: "废橡胶/玻璃",
          key: 5,
          hotProduct: [
            { label: "ASB", key: 0 },
            { label: "PP", key: 1 },
            { label: "PE", key: 2 },
            { label: "PVE颗粒", key: 3 },
          ],
        },
        // {
        //   iconName: "inst-icon7",
        //   label: "其他与服务",
        //   key: 6,
        //   hotProduct: [
        //     { label: "ASB", key: 0 },
        //     { label: "PP", key: 1 },
        //     { label: "PE", key: 2 },
        //     { label: "PVE颗粒", key: 3 },
        //   ],
        // },
      ],
      hoverIndex:0,//移动在一级菜单上的index
      isMenuPostShow:false,//是否显示二级菜单
    };
  },
  methods:{
    onHandlerMouseeter(index){ //移动到一级菜单
      this.hoverIndex = index;
      this.isMenuPostShow = true;
      if(this.hideTime){ //存在定时器
        clearTimeout(this.hideTime) //关闭定时器
      }
      
    },
    onHanderMouseleave(){ //移出一和二级级菜单 500毫秒后关闭二级菜单
      this.hideTime =  setTimeout(()=>{
        this.isMenuPostShow=false
      },100)
    },
    onHandlerMouseeterMenu(){ //移动到二级菜单  关闭定时器
      clearTimeout(this.hideTime)
    }
  }
};
</script>
<style lang="less" scoped>
.cnt-rt {
  width: 300px;
  position: relative;
  height: 505px;
  background-color: rgba(#73bc42, 0.9);
  @media screen and (max-width:1200px) {
    width: 280px;
  }
  
  .cnt-rt-title {
    color: #fff;
    height: 55px;
    background-color: #60a830;
    display: flex;
    align-items: center;
    padding: 0 30px;
    font-size: 18px;
    position: relative;
    .icon-menu {
      position: absolute;
      right: 11px;
    }
  }

  .cnt-rt-item {
    color: #fff;
    height: 75px;
    border-bottom: 1px solid #99ce76;
    
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    .icon-right {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 15px;
    }
    &:hover{
      background-color: rgba(@primary, 0.7);
    }
    &:last-child {
      border-bottom: 0;
    }
    .type-title {
      font-size: 16px;
      line-height: 26px;
      .inst-icon {
        display: inline-block;
        height: 16px;
        width: 16px;
        background-size: cover;
        position: relative;
        top: 2px;
        margin-right: 5px;
        &.inst-icon1 {
          background-image: url(../../assets/images/inst1.png);
        }
        &.inst-icon2 {
          background-image: url(../../assets/images/inst2.png);
        }
        &.inst-icon3 {
          background-image: url(../../assets/images/inst3.png);
        }
        &.inst-icon4 {
          background-image: url(../../assets/images/inst4.png);
        }
        &.inst-icon5 {
          background-image: url(../../assets/images/inst5.png);
        }
        &.inst-icon6 {
          background-image: url(../../assets/images/inst6.png);
        }
        &.inst-icon7 {
          background-image: url(../../assets/images/inst7.png);
        }
      }
    }
    .type-child {
      margin-top: 8px;
      color: #fff;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      .hot-type{
        max-width: 85px;
        overflow: hidden;
        display: inline-block;
        height: 14px;
        white-space:nowrap;
        overflow:hidden;
        text-overflow:ellipsis;
      }
      a {
        cursor: context-menu;
        color: #fff;
        margin-right: 10px;
      }
    }
  }

  .prodt-list {
    position: absolute;
    background-color: #fff;
    height: 505px;
    width: 840px;
    left: 300px;
    @media screen and (max-width:1200px) {
      left: 280px;
      width: 600px;
    }
    top: 0;
    box-shadow: 2px 0px 5px #f0f0f0;
    .prodt-item{
      display: none;
      &.hoverClass{
        display: block;
      }
    }
    .h-sidebar-box {
      margin-top: 34px;
      .h-sidebar-title {
        text-align: right;
        padding-right: 20px;
        width: 150px;
        line-height: 14px;
      }
      
      .h-sidebar-name {
        font-size: 16px;
        color: #24a739;
        margin-right: 15px;
        text-align: right;
        @media screen and (max-width:1200px) {
          text-align: left;
          margin-bottom: 20px;
          margin-left: 20px;
        }
        padding-right: 20px;
        width: 170px;
        line-height: 16px;
        .icon-right{
          color: #666;
          margin-left: 10px;
        }
      }
      .h-sidebar-moust {
        width: 572px;
        border-bottom: 1px dashed #bfbfbf;
        padding-bottom: 10px;
        overflow: hidden;
      }
      .h-sidebar-link {
        display: block;
        line-height: 16px;
        font-size: 16px;
        margin-bottom: 16px;
        float: left;
        margin-left: 18px;
        white-space: nowrap;
        color: #333;
      }
    }
  }
}

.fade-enter-active {
  transition: all .3s ease;
}
.fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.fade-enter, .fade-leave-to{
  transform: translateX(-10px);
  opacity: 0;
}
</style>